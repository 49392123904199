<template>
  <span>
    <v-sheet class="grey lighten-5 pt-5" :height="200">
      <v-row
        class="justify-center text-center"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-col cols="5">
          <a
            class="title ma-4 text-decoration-none"
            href="https://discuss.codedrills.io"
            >Discuss</a
          >
          <a href="mailto:hello@codedrills.io" class="text-decoration-none">
            <span class="title ma-4"> Contact Us </span>
          </a>
          <router-link
            class="title ma-4 text-decoration-none"
            to="/campus-chapter-program"
            >Campus Chapter</router-link
          >
          <!-- <router-link class="title ma-5 text-decoration-none" to="/blogs"> Blog </router-link> -->
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="10" :lg="2" class="d-flex justify-center">
          <a
            class="ma-2"
            rel="noopener"
            target="_blank"
            href="https://www.facebook.com/CodeDrills-102324484754529"
          >
            <v-avatar class="d-flex" tile :size="24">
              <v-img
                alt="facebook"
                class="justify-center"
                :src="require('../assets/images/social/Facebook.svg')"
              >
              </v-img>
            </v-avatar>
          </a>
          <a
            class="ma-2"
            rel="noopener"
            target="_blank"
            href="https://twitter.com/CodeDrills"
          >
            <v-avatar class="d-flex" tile :size="24">
              <v-img
                alt="twitter"
                class="justify-center"
                :src="require('../assets/images/social/Twitter.svg')"
              ></v-img>
            </v-avatar>
          </a>
          <a
            class="ma-2"
            rel="noopener"
            target="_blank"
            href="https://www.instagram.com/codedrills/"
          >
            <v-avatar class="d-flex" tile :size="24">
              <v-img
                alt="instagram"
                class="justify-center"
                :src="require('../assets/images/social/Instagram.svg')"
              >
              </v-img>
            </v-avatar>
          </a>
          <a
            class="ma-2"
            rel="noopener"
            target="_blank"
            href="https://www.youtube.com/channel/UC_VWpByh3ZQtpTV0e4MiNxQ?view_as=subscriber"
          >
            <v-avatar class="d-flex" tile :size="24">
              <v-img
                alt="youtube"
                class="justify-center"
                :src="require('../assets/images/social/YouTube.svg')"
              ></v-img>
            </v-avatar>
          </a>
          <a
            class="ma-2"
            rel="noopener"
            target="_blank"
            href="https://www.linkedin.com/company/codedrills/"
          >
            <v-avatar class="d-flex" tile :size="25">
              <v-img
                alt="linkedIn"
                class="justify-center"
                :src="require('../assets/images/social/LinkedIn.svg')"
              >
              </v-img>
            </v-avatar>
          </a>
        </v-col>
      </v-row>
      <v-row class="d-none d-lg-flex">
        <v-spacer></v-spacer>
        <v-col :cols="3"> Time: {{ time }} </v-col>
      </v-row>
    </v-sheet>

    <v-row class="pb-2 d-flex px-2">
      <v-col :cols="12" :md="5" :lg="4" class="ml-3">
        <span class="font-weight-light caption">
          &copy; {{ new Date().getFullYear() }} UNIQUE BIT TECHNOLOGIES PVT. LTD.
          All Rights Reserved.
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        :cols="12"
        :md="4"
        :lg="3"
        class="d-flex"
        v-bind:class="{
          'justify-center': $vuetify.breakpoint.mobile,
          'justify-end': !$vuetify.breakpoint.mobile,
        }"
      >
        <router-link :to="_privacyPolicyUrl" target="_blank"
          ><span class="mr-2 font-weight-light">Privacy Policy</span>
        </router-link>
        |
        <router-link :to="_tosUrl" target="_blank"
          ><span class="ml-2 font-weight-light">Terms of service</span>
        </router-link>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapState('timesync', ['serverTime']),
    time() {
      return moment.unix(this.serverTime).format('DD MMM YYYY, ddd HH:mm:ss z')
    },
    _privacyPolicyUrl() {
      return '/privacy-policy'
    },
    _tosUrl() {
      return '/terms-of-service'
    },
  },
  methods: {
    ...mapActions('timesync', ['initTimeSync']),
  },
  async created() {
    this.initTimeSync()
  },
}
</script>
