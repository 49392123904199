<template>
  <div>
    <Navbar />
    <v-main>
      <slot />
    </v-main>
    <Tail />
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Tail from '../components/Tail.vue'
export default {
  components: {
    Navbar,
    Tail,
  },
}
</script>
