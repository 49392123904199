var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-sheet', {
    staticClass: "grey lighten-5 pt-5",
    attrs: {
      "height": 200
    }
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('v-row', {
    staticClass: "justify-center text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('a', {
    staticClass: "title ma-4 text-decoration-none",
    attrs: {
      "href": "https://discuss.codedrills.io"
    }
  }, [_vm._v("Discuss")]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": "mailto:hello@codedrills.io"
    }
  }, [_c('span', {
    staticClass: "title ma-4"
  }, [_vm._v(" Contact Us ")])]), _c('router-link', {
    staticClass: "title ma-4 text-decoration-none",
    attrs: {
      "to": "/campus-chapter-program"
    }
  }, [_vm._v("Campus Chapter")])], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "10",
      "lg": 2
    }
  }, [_c('a', {
    staticClass: "ma-2",
    attrs: {
      "rel": "noopener",
      "target": "_blank",
      "href": "https://www.facebook.com/CodeDrills-102324484754529"
    }
  }, [_c('v-avatar', {
    staticClass: "d-flex",
    attrs: {
      "tile": "",
      "size": 24
    }
  }, [_c('v-img', {
    staticClass: "justify-center",
    attrs: {
      "alt": "facebook",
      "src": require('../assets/images/social/Facebook.svg')
    }
  })], 1)], 1), _c('a', {
    staticClass: "ma-2",
    attrs: {
      "rel": "noopener",
      "target": "_blank",
      "href": "https://twitter.com/CodeDrills"
    }
  }, [_c('v-avatar', {
    staticClass: "d-flex",
    attrs: {
      "tile": "",
      "size": 24
    }
  }, [_c('v-img', {
    staticClass: "justify-center",
    attrs: {
      "alt": "twitter",
      "src": require('../assets/images/social/Twitter.svg')
    }
  })], 1)], 1), _c('a', {
    staticClass: "ma-2",
    attrs: {
      "rel": "noopener",
      "target": "_blank",
      "href": "https://www.instagram.com/codedrills/"
    }
  }, [_c('v-avatar', {
    staticClass: "d-flex",
    attrs: {
      "tile": "",
      "size": 24
    }
  }, [_c('v-img', {
    staticClass: "justify-center",
    attrs: {
      "alt": "instagram",
      "src": require('../assets/images/social/Instagram.svg')
    }
  })], 1)], 1), _c('a', {
    staticClass: "ma-2",
    attrs: {
      "rel": "noopener",
      "target": "_blank",
      "href": "https://www.youtube.com/channel/UC_VWpByh3ZQtpTV0e4MiNxQ?view_as=subscriber"
    }
  }, [_c('v-avatar', {
    staticClass: "d-flex",
    attrs: {
      "tile": "",
      "size": 24
    }
  }, [_c('v-img', {
    staticClass: "justify-center",
    attrs: {
      "alt": "youtube",
      "src": require('../assets/images/social/YouTube.svg')
    }
  })], 1)], 1), _c('a', {
    staticClass: "ma-2",
    attrs: {
      "rel": "noopener",
      "target": "_blank",
      "href": "https://www.linkedin.com/company/codedrills/"
    }
  }, [_c('v-avatar', {
    staticClass: "d-flex",
    attrs: {
      "tile": "",
      "size": 25
    }
  }, [_c('v-img', {
    staticClass: "justify-center",
    attrs: {
      "alt": "linkedIn",
      "src": require('../assets/images/social/LinkedIn.svg')
    }
  })], 1)], 1)])], 1), _c('v-row', {
    staticClass: "d-none d-lg-flex"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 3
    }
  }, [_vm._v(" Time: " + _vm._s(_vm.time) + " ")])], 1)], 1), _c('v-row', {
    staticClass: "pb-2 d-flex px-2"
  }, [_c('v-col', {
    staticClass: "ml-3",
    attrs: {
      "cols": 12,
      "md": 5,
      "lg": 4
    }
  }, [_c('span', {
    staticClass: "font-weight-light caption"
  }, [_vm._v(" © " + _vm._s(new Date().getFullYear()) + " UNIQUE BIT TECHNOLOGIES PVT. LTD. All Rights Reserved. ")])]), _c('v-spacer'), _c('v-col', {
    staticClass: "d-flex",
    class: {
      'justify-center': _vm.$vuetify.breakpoint.mobile,
      'justify-end': !_vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 12,
      "md": 4,
      "lg": 3
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm._privacyPolicyUrl,
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "mr-2 font-weight-light"
  }, [_vm._v("Privacy Policy")])]), _vm._v(" | "), _c('router-link', {
    attrs: {
      "to": _vm._tosUrl,
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "ml-2 font-weight-light"
  }, [_vm._v("Terms of service")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }